import SiteWrapper from "../../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../../components/mdrender";
import * as md from "../../../components/mdrender.module.sass";
import TitleBlock from "../../../components/title";

import "./reliable-alerting@gen.scss";

const GeneratedProductFeaturesReliableAlerting = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          多様なアラート機能 - PagerDuty正規代理店 - 株式会社Digital Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta property="twitter:description" content="アラート管理" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta name="description" content="アラート管理" />

        <meta
          property="og:title"
          content="多様なアラート機能 - PagerDuty正規代理店 - 株式会社Digital Stacks"
        />

        <meta property="og:description" content="アラート管理" />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/product/features/reliable-alerting/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/product/features/reliable-alerting/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Products"
        textGreen="多様なアラート機能"
        overview=""
      />

      <div className="raWrapper">
        <div className="center-2 first-section">
          <h2 className="center-title">アラート管理</h2>
          <p className="center-para">
            すべてのITオペレーションと開発運用チームからアラートを集中管理し、重要なインシデントを各チームに最適な方法でチームに警告します。
          </p>
        </div>
        <div className="max-930px-img">
          <iframe
            width="100%"
            height={420}
            src="https://www.youtube.com/embed/B8KSTyPmHwE"
            title="YouTube video player"
            frameBorder={0}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
        {/* details */}
        <div className="center-1">
          <h2 className="center-title">アラートの詳細</h2>
        </div>
        <div className="three-col-without-cta">
          <div className="card">
            <div className="title">
              <p>マルチユーザアラート</p>
            </div>
            <p>
              一度に複数のレスポンダーに警告することで、チーム全体に問題を知らせることができます。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>
                インシデントの
                <br />
                自動エスカレーション
              </p>
            </div>
            <p>
              インシデントを二次対応者または管理者に自動的にエスカレート <br />
              します。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>インシデントステータスの通知</p>
            </div>
            <p>
              カスタマイズ可能な更新通知を使用して、インシデントのステータスを最新の状態に保ちます。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>詳細なインシデント</p>
            </div>
            <p>
              インシデントの詳細には、グラフ、画像、ランブックリンク、または電話会議へのリンクを含めることができます。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>複数のアラートタイプ</p>
            </div>
            <p>
              SMSメッセージ、モバイルアプリのプッシュ通知、電話、または電子メールでアラートを送信します。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>HTMLメール通知</p>
            </div>
            <p>
              PagerDutyのメール通知で重要な詳細を参照したり、グラフ、画像などを直接監視したりすることで、チームは応答までの時間を節約{" "}
              <br /> できます。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>
                カスタマイズ可能な
                <br />
                アラート頻度
              </p>
            </div>
            <p>各イベントの通知頻度をカスタマイズします。</p>
          </div>
          <div className="card">
            <div className="title">
              <p>緊急時のアラート</p>
            </div>
            <p>
              緊急性の高いイベントに対して、さまざまな警告方法と経路を設定{" "}
              <br /> します。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>時刻によるアラート</p>
            </div>
            <p>
              時刻に基づいて、さまざまなアラート方法とイベントの経路を設定{" "}
              <br /> します。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>アラート配信確認</p>
            </div>
            <p>
              エンドユーザのデバイスにSMSおよび電話のアラートが配信されていることを確認します。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>インシデント履歴</p>
            </div>
            <p>
              インシデントのすべての通知とステータスの更新の証跡を保管します。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>インシデントの手動割り当て</p>
            </div>
            <p>
              手動でインシデントを作成し、インシデントの割り当てをすることができます。
            </p>
          </div>
        </div>
        <div className="conclusion">
          PagerDutyのインシデント解決プラットフォームは、ノイズを削減し、
          <br />
          インシデントをより迅速に解決するのに役立ちます。
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedProductFeaturesReliableAlerting;
